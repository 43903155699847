<template>
  <div class="view-home" style="background-color: #FAFAFB;">
    <div class="page-container">
      <div class="title-block">
        <h1>Статистика</h1>
      </div>
      <el-select v-model="value" placeholder="За период">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value: ''
    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>

<style>

.page-container {
  padding: 30px 48px;
}

</style>